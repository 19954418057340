export const firebaseCfg = {
	useEmulators: false,
	credentials: {
		apiKey: 'AIzaSyD0FehAXyTeAMRX-t32lAe0J4hdhXc3VBo',
		appId: '1:622354523249:web:4e7624495540bf79b7a7d0',
		authDomain: 'gregharner-84eb9.firebaseapp.com',
		messagingSenderId: '622354523249',
		projectId: 'gregharner-84eb9',
		storageBucket: 'gregharner-84eb9.appspot.com',
	},
	nodeSandboxEndpoint: 'http://localhost:5001/gregharner-84eb9/us-central1/',
	pythonSandboxEndpoint: 'http://localhost:5001/gregharner-84eb9/us-central1/',
	firebaseEndpoint: 'https://us-central1-mas-development-53ac7.cloudfunctions.net',
	cloudRunEndpoint: '',
};
